import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  BAD_REQUEST_PAGE,
  CANCEL_PAGE,
  FAILED_PAGE_400,
  FAILED_PAGE_500,
  SERVER_ERROR_PAGE,
  SUCCESS_PAGE,
} from "./constants/path.c";
import ErrorPage from "./pages/error";
import SuccessfulPage from "./pages/successful";
import HomePage from "./pages/home";
import FailedPage from "./pages/failed";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    children: [
      {
        path: "HostedPaymentPage",
        element: <HomePage />,
        children: [
          {
            path: ":slug",
            element: <HomePage />,
            errorElement: <ErrorPage error={404} />,
          },
        ],
      },
    ],
  },
  {
    path: BAD_REQUEST_PAGE,
    element: <ErrorPage error={400} />,
  },
  {
    path: `/${SUCCESS_PAGE}/:transaction`,
    element: <SuccessfulPage />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: `/${FAILED_PAGE_400}`,
    element: <FailedPage />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: `/${FAILED_PAGE_500}`,
    element: <FailedPage />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: SERVER_ERROR_PAGE,
    element: <ErrorPage error={500} />,
    errorElement: <ErrorPage error={404} />,
  },

  {
    path: `/${CANCEL_PAGE}`,
    element: <ErrorPage error={499} />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: "*",
    element: <ErrorPage error={404} />,
  },
  {
    path: "/jp",
    element: <HomePage />,
    children: [
      {
        path: "HostedPaymentPage",
        element: <HomePage />,
        children: [
          {
            path: ":slug",
            element: <HomePage />,
          },
        ],
      },
    ],
  },
  {
    path: `/jp/${BAD_REQUEST_PAGE}`,
    element: <ErrorPage error={400} />,
  },

  {
    path: `/jp/${SUCCESS_PAGE}/:transaction`,
    element: <SuccessfulPage />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: `/jp/${FAILED_PAGE_400}`,
    element: <FailedPage />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: `/jp/${FAILED_PAGE_500}`,
    element: <FailedPage />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: `/jp/${SERVER_ERROR_PAGE}`,
    element: <ErrorPage error={500} />,
    errorElement: <ErrorPage error={404} />,
  },

  {
    path: `/jp/${CANCEL_PAGE}`,
    element: <ErrorPage error={499} />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: "/en",
    element: <HomePage />,
    children: [
      {
        path: "HostedPaymentPage",
        element: <HomePage />,
        children: [
          {
            path: ":slug",
            element: <HomePage />,
          },
        ],
      },
    ],
  },
  {
    path: `/en/${BAD_REQUEST_PAGE}`,
    element: <ErrorPage error={400} />,
  },

  {
    path: `/en/${SUCCESS_PAGE}/:transaction`,
    element: <SuccessfulPage />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: `/en/${FAILED_PAGE_500}`,
    element: <FailedPage />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: `/en/${FAILED_PAGE_400}`,
    element: <FailedPage />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: `/en/${SERVER_ERROR_PAGE}`,
    element: <ErrorPage error={500} />,
    errorElement: <ErrorPage error={404} />,
  },

  {
    path: `/en/${CANCEL_PAGE}`,
    element: <ErrorPage error={499} />,
    errorElement: <ErrorPage error={404} />,
  },
  {
    path: "*",
    element: <ErrorPage error={404} />,
  },
]);
function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
