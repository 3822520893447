export const FORM_FIELD_NAMES={
    CVV:"cvv",
    CARD_NUMBER: "cardnumber",
    COUNTRY: "country",
    BILLING_INFORMATION:"Billing Information",
    POSTAL_CODE: "postal_code",
    HAS_VISA:"hasVISA",
    HAS_MASTER_CARD: "hasMastercard",
    HAS_JCB:"hasJCB",
    HAS_AMEX:"hasAmex",
    HAS_DINERSCLUB:"hasDinersClub",
    AMOUNT:"amount",
    LISTENER: "listener",
    ENTER_AMOUNT: "Enter Amount",
    FIRST_NAME: "firstname",
    LAST_NAME: "lastname",
    EMAIL:"email",
    PHONE:"phone",
    ADDRESSLINEONE:'address1',
    CITY:"city",
    // MEMO:"memo",
};
export const hideIfHasValueField = [ "country","postal_code","email","phone",'address1',"city"];
export const COMPONENT = {
    DAS_MASKED_TEXT_FILED:"DasMaskedTextField",
    DAS_SELECT_OPTIONS: "DasSelectOptions",
    DAS_TEXT_FIELD: "DasTextField",
    TEXT_FIELD: "TextField",
    DAS_FORM_SPY: "DasFormSpy",
    DAS_FORM_LABEL: "DasFormLabel"
};

export const VALIDATOR_NAME={
    DATE_VALIDATOR: "Date-Validator",
    CARD_VALIDATOR: "Card-Validator"
};
export const CUSTOM_REGEX={
    name: "^[A-Za-z '.ぁ-んァ-ンー々〆〤一-龯々〆〤]+(?:[A-Za-zぁ-んァ-ンー々〆〤]+)*$"
};