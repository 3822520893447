const PUBLIC_KEY= `-----BEGIN PUBLIC KEY-----
   MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuPDHXuzVo+VJDvKWaQnQ
   bikzB4tfq13nkriZGqop4q0KECWboBVsz249WUmGxoQMEW5BmSTSoGeUhxCJ1+m0
   LfRZ7HfTFBqNR1npbETsq55AzsPacwLH+bgMiQI0CkNxDRk2CEu5LVH+Ug+BG3Uq
   4ngROA0g1B+Sc/k7yjJQ3Aaoxsh5aA+eQxCyW26Dt16Vy7qbRFM3UNoLTlDsZsjs
   g0IWPwtLSnk5HdySHF/0ALvg33BxZwHAyvjsEClKpvs/S8soQwtAZ7ryxFMJJqTI
   VCjN9vof2c5H7nmk+PJ9idVeAlN0ygafY7xd1Rpk8P0EFJKj4HUqRpPGLE8lglUs
   HQIDAQAB
   -----END PUBLIC KEY-----`

   export default PUBLIC_KEY;