import LOADER from "../assets/spinner.gif";
import IFRAMELOADER from "../assets/iframe-loader.gif";
import useGetParameter from "../hooks/useGetParameter";
import { useEffect, useState } from "react";
import { URL_PARAM } from "../../constants/url-param.c";

export const Loader = () => {
  const isIFrame = useGetParameter(URL_PARAM.isIFrame);
  const [loaderImage, setLoaderImage] = useState(LOADER);
  useEffect(() => {
    if (isIFrame) {
      setLoaderImage(IFRAMELOADER);
    }
  }, [isIFrame]);

  return (
    <div className="loader-container">
      <img className="loader-gif" src={loaderImage} alt="Loader" />
    </div>
  );
};

export default Loader;
