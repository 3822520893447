import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from 'react-ga'

type CategoryGAKey = "payment" | "applePay" | "googlePay" | "paymentFailed" | "paymentSuccess" | "paymentCancelled";
export const categoryGA:{ [key in CategoryGAKey ]: string; } = {
    payment:"PAYMENT",
    applePay:"APPLE-PAY",
    googlePay:"GOOGLE-PAY",
    paymentFailed:"PAYMENT-FAILED",
    paymentSuccess:"PAYMENT-SUCCESS",
    paymentCancelled:"PAYMENT-CANCELLED",
}

/**
 * Event for click 
 * action : String
 * label : String
 * value : Number 
 */
const pushClickEventAnalytics = (action:string, label:string="" , value:number|null=null):void =>{
    if(process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS && process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS === 'true'){
        // console.log(`useGoogleAnalyticsClickEvent --> action : ${action}, label : ${label}`);
        ReactGA.event({category:'Click', action, label , ...(value && {value})});
    }
}

export function useGoogleAnalyticsPageView(){
    const location = useLocation();
    useEffect(() => {
        ReactGA.pageview(`${location.pathname}${location.search}`);
        // console.log(`useGoogleAnalyticsPageView --> ${location.pathname}${location.search}`);
      }, [location])
    return;
}

export function useGoogleAnalyticsClickEvent(){
    return pushClickEventAnalytics
}

