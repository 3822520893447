const ModelTypeOne = (props: any) => {
    const { emailAndPhone, cityAndAddress, postalCodeField, countryField } = props;

    return (<div className="billing-section">
        <div className="center">  {postalCodeField} </div>
        {emailAndPhone}
        <div className="country-postcode">{cityAndAddress}</div>
        <div className="center">  {countryField} </div>
    </div>)
}
export default ModelTypeOne;