import {
  useFieldApi,
  useFormApi,
} from "@data-driven-forms/react-form-renderer";
import { useTranslation } from "react-i18next";
import CVV from "../../assets/cvv.png";
import MaskedInput from "react-text-mask";
import { CARD_NAMES } from "../../../constants/cards.c";
import { FORM_FIELD_NAMES } from "../../../constants/form-field-names";
import VISA from "../../assets/cards/visa.png";
import MASTERCARD from "../../assets/cards/mastercard.png";
import AMEX from "../../assets/cards/amex.png";
import JCB from "../../assets/cards/jcb.png";
import DINERSCLUB from "../../assets/cards/dinersclub.png";
import { getCardType } from "../../hooks/getCardType";
import { isJapanese } from "../../hooks/isJapanese";

const DasMaskedTextField = (props: any) => {
  const {
    customProp,
    label,
    input,
    isRequired,
    placeholder,
    inputLabel,
    meta: { error, touched },
    FieldArrayProvider,
    dataType,
    ...rest
  } = useFieldApi(props);
  const { getState } = useFormApi();
  const { values } = getState();
  const { t } = useTranslation();
  const input_Label = t(inputLabel);
  const cardType = getCardType(input.value);

  let cardImage = cardType ? cardType : "default";
  const AMERICANEXPRESS = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  const JAPANESELANGAMERICANEXPRESS = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  const OTHERCARDS = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];
  const JAPANESELANGOTHERCARDS = [
    /[1-9]/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const cardTypes = [
    { key: "hasVISA", src: VISA, alt: "Visa-card" },
    { key: "hasMastercard", src: MASTERCARD, alt: "Mastercard" },
    { key: "hasAmex", src: AMEX, alt: "AMERICANEXPRESS-card" },
    { key: "hasJCB", src: JCB, alt: "JCB-card" },
    { key: "hasDinersClub", src: DINERSCLUB, alt: "DINERSCLUB-card" },

  ];
  const japaneseLang = isJapanese();
  const maskingPattern = {
    japaneseLang: {
      AMERICANEXPRESS: JAPANESELANGAMERICANEXPRESS,
      OTHERCARDS: JAPANESELANGOTHERCARDS
    },
    engLang: {
      AMERICANEXPRESS: AMERICANEXPRESS,
      OTHERCARDS: OTHERCARDS
    }
  }
  const maskType = japaneseLang ? "japaneseLang" : "engLang";
  const mask = maskingPattern[maskType];
  return (
    <div className="inputgroup">
      <label className="input-label">{input_Label}</label>
      {input?.name === FORM_FIELD_NAMES.CARD_NUMBER && (
        <div className="payment-cards">
          {cardTypes.map(
            (card, index) =>
              values?.[card.key] && (
                <img
                  key={index}
                  draggable="false"
                  className="card-icons"
                  src={card.src}
                  alt={card.alt}
                />
              )
          )}
        </div>
      )}
      {props.name === FORM_FIELD_NAMES.CARD_NUMBER ? (
        <img
          draggable="false"
          className="credit-icon"
          src={`/cards/${cardImage}.png`}
          alt="Card"
        />
      ) : props.name === FORM_FIELD_NAMES.CVV ? (
        <img
          draggable="false"
          className="credit-icon"
          src={CVV}
          alt="cvv-card"
        />
      ) : (
        ""
      )}
      <span className={error && touched ? "errors-class" : ""}>
        <MaskedInput
          type={"tel"}
          mask={
            cardType === CARD_NAMES.AMERICAN_EXPRESS
              ? mask.AMERICANEXPRESS
              : mask.OTHERCARDS
          }
          id={input.name}
          {...input}
          {...rest}
          placeholder={t(placeholder)}
          guide={false}

        />
        <span className="inputcheck">
          {!touched ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#ccc"
              viewBox="0 0 16 16"
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
            </svg>
          ) : error && touched ? (
            <svg
              className="removebtn"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#ccc"
              className="greentick"
              viewBox="0 0 16 16"
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
            </svg>
          )}
        </span>
        {/* {touched && error && <p >{error}</p>} */}
      </span>
    </div>
  );
};
export default DasMaskedTextField;
