import React from 'react'

const Existing = (props: any) => {
    const { billingInfoLabel, emailAndPhone, cityAndAddress, countryAndPostalCode } = props;
            return (<>
                {billingInfoLabel}
                {cityAndAddress}
                {emailAndPhone}
                {countryAndPostalCode}
            </>)
}

export default Existing