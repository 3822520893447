export const CARDS: any = {
    visa: "hasVISA",
    mastercard: "hasMastercard",
    jcb: "hasJCB",
    "american-express": "hasAmex",
    "diners-club":"hasDinersClub"
};

export const CARD_NAMES={
    AMERICAN_EXPRESS: "american-express"
}
