import React, { useEffect, useState } from "react";
import FormRenderer from "@data-driven-forms/react-form-renderer/form-renderer";
import { PaymentSchema } from "./hpp/schema/payment.schema";
import FormTemplate from "./hpp/template/FormTemplate";
import { ValidatorMapper } from "./validator/ValidatorMapper";
import { useNavigate } from "react-router-dom";
import { handleCancel } from "../hooks/handleCancel";
import { CURRENCY_CODES } from "../../constants/currency-codes.c";
import { useGoogleAnalyticsClickEvent, categoryGA }from "../../components/hooks/google-analytics";
import { isJapanese } from "../hooks/isJapanese";

const HPPForm = ({
  hasGooglepayOrApplePay,
  CURRENCY,
  apiData,
  ComponentMapper,
  handleSubmit,
}: any) => {
  const navigate = useNavigate();
  const pushClickEventAnalytics = useGoogleAnalyticsClickEvent();  // For google analytics
  const [currency, setCurrency] = useState(CURRENCY);
  useEffect(() => {
    const currencies = [
      CURRENCY_CODES.JPY,
      CURRENCY_CODES.SGD,
      CURRENCY_CODES.USD,
      CURRENCY_CODES.HKD,
      CURRENCY_CODES.PHP
    ];
    if (!currencies.includes(currency)) {
      setCurrency(CURRENCY_CODES.JPY);
    }
  }, [currency]);
  const isJapaneseLanguage = isJapanese();
  return (
    <FormRenderer
      schema={PaymentSchema(hasGooglepayOrApplePay, apiData?.config?.modelType, isJapaneseLanguage)[currency]}
      initialValues={apiData}
      FormTemplate={FormTemplate}
      componentMapper={ComponentMapper}
      onSubmit={handleSubmit}
      onCancel={() => { 
        pushClickEventAnalytics(categoryGA.paymentCancelled,` ${localStorage.getItem("slug")}`); // For google analytics
        return handleCancel(navigate, apiData)
      }}
      validatorMapper={ValidatorMapper}
    />
  );
};

export default HPPForm;
