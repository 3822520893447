import React from "react";
// import LOGO from "../assets/logo/das-small-logo.jpg";
// import backgroundimage from "../assets/background/payment-bg.jpg";
export default function Error({ status = "", title = "", heading = "", description }: {
    status?: string | undefined;
    title?: string | undefined;
    heading?: string | undefined;
    description: any;
}) {
    return (
        <>
            <div className="payment-container error-container">
                {/* <img
                    className="background-image"
                    src={backgroundimage}
                    alt="Background Poster"
                /> */}
                <div className="combo-cont">
                    {/* <div className="logo-box">
                        <img className="logo-image" src={LOGO} alt="das-small-logo" />
                    </div> */}
                    <div className="payment-box error-box">
                        <div className="payment-img-container">
                            <h2>{status}</h2>
                            <h4 className="payment-status payment-failed-red">{title}</h4>
                            <h6 className="payment-status-sub-heading">{heading}</h6>
                        </div>
                        <p className="transaction-id-no">{description}</p>
                    </div>
                </div>
            </div>
           
        </>
    );
}
