import ReactDOM from "react-dom/client";
import App from "./App";
import "./i18n";
import "./App.css";
import * as Sentry from "@sentry/react";
import ReactGA from 'react-ga';
import { googleAnalyticsTrackingId, isGoogleAnalyticsEnabled } from "./constants/google-analytics";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if(isGoogleAnalyticsEnabled){
  ReactGA.initialize(googleAnalyticsTrackingId);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
