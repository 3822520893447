import { useState } from "react";
import CustomAccordian from "../../../custom-accordian/CustomAccordian";

const AccordionModelTypeOther = (props:any) => {
    const { hideBillingAddress, billingInfoLabel, emailAndPhone, cityAndAddress, countryAndPostalCode } = props;

    const [open, setOpen] = useState(false);
    return (
        <>
            <CustomAccordian
                hideBillingAddress={hideBillingAddress}
                button={billingInfoLabel}
                open={open}
                setOpen={setOpen}
            >
                <div className="billing-section">
                    {emailAndPhone}
                    <div className="country-postcode">{cityAndAddress}</div>
                </div>
            </CustomAccordian>
            {countryAndPostalCode}
        </>
    )
}
export default AccordionModelTypeOther;