import valid from "card-validator";
import i18n from "../../../i18n";
import { CARDS } from "../../../constants/cards.c";

export const ValidatorMapper = {
    "Card-Validator": () => (value: any, allValues: any) => {
        if (value) {
            let cardType = valid.number(value).card?.type;
            const cardnumberlength = allValues.cardnumber.replace(/\s/g, "").length;
            const cardLength = cardType === "american-express" ? 15 : 16;
            if (
                !cardType ||
                !allValues[CARDS[cardType]] ||
                cardnumberlength !== cardLength
            ) {
                return i18n.t("Validation.InvalidCard");
            }
        }
    },
    "Date-Validator": () => (value:any) => {
        if (value) {
            const values = value.split("/");
            const month = parseInt(values[0]);
            const year = parseInt(values[1]);
            const currDate = new Date();
            const currMonth = currDate.getMonth();
            const currYear = parseInt(currDate.getFullYear().toString().substring(2));
            if (
                month < 1 ||
                month > 12 ||
                (year === currYear && month < currMonth + 1) ||
                year < currYear ||
                isNaN(year)
            ) {
                return i18n.t("Validation.ExpiryDate")
            }
        }
    },
};
