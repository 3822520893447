import React from 'react'

const DasORSeparator = () => {
    return (
        <div className='pay-seperator' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <hr style={{ width: "45%", opacity: 0.3 }} />
            <p style={{ width: "35%", textAlign: "center", fontSize:"13px" }}>
                Or Pay With Card
            </p>
            <hr style={{ width: "45%", opacity: 0.3 }} />
        </div>
    )
}

export default DasORSeparator;
