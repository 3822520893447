import { BASE_API_URL } from "../constants/api-path.c";

function Api() {
  return {
    get: request("GET"),
    post: request("POST"),
    put: request("PUT"),
    delete: request("DELETE"),
  };
  type MethodType = "GET" | "POST" | "PUT" | "DELETE";
  function request(method: MethodType) {
    return async (url: string, body?: any) => {
      const requestOptions: any = {
        method,
        headers: { ...authHeader(url) },
        credentials: "include",
      };
      if (body) {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      }
      //   return {
      //     "id": "4deebfb0-bcdf-4bae-abcf-d62187acfd53",
      //     "amount": null,
      //     "success_url": "",
      //     "decline_url": "",
      //     "cancel_url": null,
      //     "company": "High Mocha Pvt. Ltd. ",
      //     "address": "tokyo, tokyo, JP, 87870009",
      //     "hasVISA": true,
      //     "hasMastercard": true,
      //     "hasJCB": false,
      //     "hasAmex": false,
      //     "country": "JP",
      //     "currency": "USD",
      //     "entity": "JP",
      //     "has3DS": true,
      //     "product": null,
      //     "threedsProvider": 1
      // }
      return fetch(url, requestOptions).then(
        (res) => {
          // commenting this becuase in case of failed payment page we need Ref which we get from capture api response
          // if (res.status > 400) {
          //   throw new Error("Server responds with error!");
          // }
          return res.json();
        },
        (err) => {
          console.log("err", err);
        }
      );
    };
  }

  // helper functions

  function authHeader(url: string) {
    const isApiUrl = url.startsWith(BASE_API_URL);
    if (isApiUrl) {
      return {
        "x-secret-key": process.env.REACT_APP_PUBLIC_SECRET_KEY,
        "x-api-key": process.env.REACT_APP_PUBLIC_API_KEY,
      };
    } else {
      return {};
    }
  }
}

export default Api;
