import { ERROR_RESPONSE } from "../../constants/error-response.c";
import { useTranslation } from "react-i18next";
import Error from "../../components/error/Error.component";
import HppWrapper from "../../components/main-wrapper/HppWrapper";
import { useGoogleAnalyticsPageView } from "../../components/hooks/google-analytics";
import { base64ToString } from "../../utils/helper";
import useGetParameter from "../../components/hooks/useGetParameter";
import ReverseCounter from "../../components/reverse-counter/ReverseCounter";
import { REDIRECT_COUNT } from "../../constants/timeout-intervals";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { URL_PARAM } from "../../constants/url-param.c";
import { ERROR_PAGE_CODE } from "../../constants/error-page-code.c";

const ErrorPage = ({ error = 404 }: any) => {
  useGoogleAnalyticsPageView(); // For google analytics
  let ErrorType = ERROR_RESPONSE[error];
  const data = useGetParameter(URL_PARAM.data);
  const cancel_url = data && base64ToString(data);
  const { t } = useTranslation();

  const weAreOnCancelPage = error === ERROR_PAGE_CODE.CANCEL;

  useEffect(() => {
    if (cancel_url && weAreOnCancelPage) {
      setTimeout(() => {
        window.location.href = cancel_url;
      }, REDIRECT_COUNT.CANCEL * 1000);
    }
  }, [cancel_url, weAreOnCancelPage]);

  return (
    <HppWrapper>
      <div className="w-40rem">
        <div className="background-wrapper flex-column">
          <Error
            status={t(ErrorType.code)}
            title={t(ErrorType.message)}
            heading={t(ErrorType.heading)}
            // if we are on cancel page and we have cancel url then show the back to merchant button and reverse counter
            description={weAreOnCancelPage && cancel_url ?
              <>
                <NavLink className="back-home w-50"
                  to={cancel_url}>
                  {t("Payment_Failed.Prev_Link")}{" "}
                </NavLink>
                <ReverseCounter
                  count={REDIRECT_COUNT.CANCEL}
                  prefixText={t("Payment_Successful.Redirect_Prefix_Text")}
                  suffixText={t("Payment_Successful.Redirect_Suffix_Text")}
                />
              </>
              : t(ErrorType.description)}
          />

        </div>
      </div>
    </HppWrapper>
  );
};

export default ErrorPage;
