export const PAYMENT_PAYLOAD_TO_ENCRYPT = [
  "amount",
  "cardnumber",
  "cvv",
  "firstname",
  "lastname",
  "dfReferenceId",
  "monthyear",
];

export const ALLOWED_PAYLOAD_KEYS = [
  "URLKey",
  "billing_address",
  "amount",
  "cardnumber",
  "cvv",
  "firstname",
  "lastname",
  "dfReferenceId",
  "monthyear",
  "tz",
];
