import { SLUG_API_URL } from "../../constants/api-path.c";
import { COUNTRIES } from "../../constants/countries.c";
import { ERROR_PAGE, NOT_FOUND_PAGE } from "../../constants/path.c";
import Api from "../../utils/Api";
import { cardinal3ds } from "./cardinal3ds";
import { isJapanese } from "./isJapanese";

export const getApiData = (
  slug: any,
  setAmountLabel: any,
  navigate: any,
  setDasLoader: any,
  setApiData: any,
  setProductList: any,
  setSessionID: any,
  setJwt: any,
  setThreedsProvider: any
) => {
  const japanese = isJapanese();
  if (slug) {
    Api()
      .get(`${SLUG_API_URL}/${slug}`)
      .then((res) => {
        if (res?.id) {
          res.amount = res.amount > 0 ? res.amount : "";
          setAmountLabel(res?.amount && res?.amount > 0 ? res?.amount : 0);
          if (res.statusCode >= 400 && res.statusCode <= 499) {
            navigate(`/${ERROR_PAGE}/${400}`);
          }
          if (res.statusCode >= 500 && res.statusCode <= 599) {
            navigate(`/${ERROR_PAGE}/${500}`);
          }
          setDasLoader(false);
          setApiData({
            ...res,
            // hasApplePay: true,
            // hasGooglePay: true,
            // amount:null,
            // productList: [{ Image: "/products/default.jpg", Name: "Car", Quantity: "8", Price: "4" },
            //   { Image: "/products/default.jpg", Name: "Car", Quantity: "5", Price: "1" }],
            ...res.billingAddress,
            country: COUNTRIES.find((country) => country.value === res.country),
          });
          setThreedsProvider(res.threedsProvider);
          res?.product &&
            Array.isArray(res.product) &&
             setProductList(res.product);
        //  setProductList([{ Image: "/products/default.jpg", Name: "Car", Quantity: "5", Price: "300" }, { Image: "/products/default.jpg", Name: "Car", Quantity: "5", Price: "300" }])
          
          if (res.has3DS) {
            switch (res.threedsProvider) {
              case 1:
                cardinal3ds(slug, setSessionID, setJwt, navigate);
                break;
              case 2:
                setSessionID("NOTREQUIRED");
                break;
              default:
                return;
            }
          }
        } else {
          if (japanese) {
            navigate(`/jp/${NOT_FOUND_PAGE}`);
          } else {
            navigate(`/${NOT_FOUND_PAGE}`);
          }
        }
      })
      .catch(() => {
        navigate(`/${NOT_FOUND_PAGE}`);
      });
  } else {
    navigate(`/${NOT_FOUND_PAGE}`);
  }
};
