import React, { useEffect } from "react";

interface RedirectTo3DSProps {
  htmlContent: string; // Change the type to match the actual type of htmlContent
}

const RedirectTo3DS: React.FC<RedirectTo3DSProps> = ({ htmlContent }) => {
  useEffect(() => {
    const handleSubmitForm = () => {
      const container = document.createElement("div");
      container.innerHTML = htmlContent;

      const form = container.querySelector("#threedsChallengeRedirectForm");
      if (form) {
        document.body.appendChild(form);

        // Type cast the form element to HTMLFormElement
        const typedForm = form as HTMLFormElement;

        typedForm.submit();
      }
    };

    handleSubmitForm();
  }, [htmlContent]);

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default RedirectTo3DS;
