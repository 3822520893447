import React from 'react'
import { CURRENCY_SYMBOL } from "../../constants/currency-symbol.c";
import { CURRENCY_CODES } from "../../constants/currency-codes.c";
import { useTranslation } from 'react-i18next';

const OrderOverView = ({ ccy, amount }:any) => {
  const { t } = useTranslation();
  return (
    <div className="oreder-overview">
      <h4>
        {t("merchant.Order_Overview")}
        <span>
          {CURRENCY_SYMBOL[ccy]}
          {ccy === CURRENCY_CODES.JPY ? amount : amount?.toFixed(2)}
          <strong> {ccy}</strong>
        </span>
      </h4>
    </div>
  );
};

export default OrderOverView