import React from 'react'
import { applePayController } from '../apple-pay-button/components/apple-pay';

const DasCustomGpayButton = ({ slug,apiData, setAmountPopup }: any) => {
  return (
    <>
      <button
        className={
          (apiData?.hasGooglePay === true && apiData?.hasApplePay === false) ||
          Boolean(!applePayController.isAppleSessionAvailable())
            ? "google-pay-button full-width-button"
            : "google-pay-button"
        }
        onClick={() => {
      localStorage.setItem("slug", slug);

          setAmountPopup((prev: any) => {
            return {
              ...prev,
              open: true,
              logo: "/google-pay.png",
              type: "gpay",
            };
          });
        }}
      >
        <img
          src={"/google-pay.png"}
          alt="Google Pay Logo"
          className="google-pay-icons"
        />
      </button>
    </>
  );
};

export default DasCustomGpayButton