import { useFieldApi } from "@data-driven-forms/react-form-renderer";
import { useTranslation } from "react-i18next";
const DasFormLabel = (props: any) => {
  const {
    customProp,
    label,
    input,
    isRequired,
    FieldArrayProvider,
    dataType,
    rules = null,
    ...rest
  } = useFieldApi(props);
  const { t } = useTranslation();
  const placeholderLabel = t(label);
  return (
    <h5 id={input.name} placeholder={placeholderLabel} {...input} {...rest}>
      {placeholderLabel}
      {rules?.length > 0 && (
        <div className="info-wrap">
          <div className="info-icon">
            <span>i</span>
            <span className="hidden-span">
              {rules?.map((item: any, index: number) => {
                return (
                  <span
                    key={index}
                    className="hidden-text"
                    style={{ color: "black" }}
                  >
                    <p>
                    &#10148; &nbsp;
                      {t(item)}
                    </p>
                  </span>
                );
              })}
            </span>
          </div>
        </div>
      )}
    </h5>
  );
};
export default DasFormLabel;
