import { CANCEL_PAGE } from "../../constants/path.c";
import { stringToBase64 } from "../../utils/helper";
import { isJapanese } from "./isJapanese";

export const handleCancel = (navigate:any,apiData:any) => {
 const japanse= isJapanese();
  const data = Boolean(apiData?.cancel_url) ?
    `?data=${stringToBase64(apiData?.cancel_url)}`
    : ""
  
 if(japanse){
  navigate(
    `/jp/${CANCEL_PAGE}${data}`
  );
 } else {
  navigate(
    `/${CANCEL_PAGE}${data}`
  );
 }
};