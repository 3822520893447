import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function CustomAccordian({ hideBillingAddress, button, children, open, setOpen }:any) {
    if (hideBillingAddress){
        return (
            <div>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        onClick={() => setOpen(!open)}
                    >
                        {button}
                    </AccordionSummary>
                    <AccordionDetails>
                        {children}
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }else{
        return (<>
            {button}
            { children }
        </>)
    }

}
