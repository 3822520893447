import { useEffect } from "react";
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api";

export const DasFieldListener = ({ setCardNumber, setAmountLabel }:any) => {
  const { getState, change }:any = useFormApi();

  const { cardnumber, amount } = getState().values;

  useEffect(() => {
    if (typeof amount === "number") {
      setAmountLabel(amount ? amount : 0);
    }
    if (typeof amount === "undefined") {
      setAmountLabel(0);
    }
    if (amount < 0) {
      change(0);
    }
  }, [amount, change, setAmountLabel]);

  useEffect(() => {
    if (cardnumber) {
      setCardNumber(cardnumber);
    }
  }, [cardnumber, setCardNumber]);

  return null;
};
