import { useFieldApi } from "@data-driven-forms/react-form-renderer";
import { useTranslation } from "react-i18next";
import { FORM_FIELD_NAMES } from "../../../constants/form-field-names";

export const DasTextField = (props: any) => {
  const {
    customProp,
    label,
    input,
    inputLabel,
    placeholder,
    isRequired,
    meta: { error, touched, initial },
    FieldArrayProvider,
    dataType,
    classN = "",
    ...rest
  } = useFieldApi(props);
  const { t } = useTranslation();
  let isReadOnly = false;
  if (initial && input.name === FORM_FIELD_NAMES.AMOUNT) {
    isReadOnly = true;
  }
  const placeholderLabel = t(placeholder);
  const input_Label = t(inputLabel);
  return (
    <div className={`inputgroup ${classN}`}>
      {input_Label && (
        <div className="flex-row">
          <label className="input-label">{input_Label}</label>

        </div>
      )}

      <span className={error && touched ? "errors-class" : ""}>
        <input
          id={input.name}
          {...input}
          {...rest}
          disabled={isReadOnly}
          placeholder={placeholderLabel}
        />
        <span className="inputcheck">
          {!touched || input.value.length < 1 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#ccc"
              viewBox="0 0 16 16"
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
            </svg>
          ) : error && touched ? (
            <svg
              className="removebtn"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 24 24"
            >
              <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              fill="#ccc"
              className="greentick"
              viewBox="0 0 16 16"
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
            </svg>
          )}
        </span>
      </span>
    </div>
  );
};
