import { useFieldApi } from "@data-driven-forms/react-form-renderer";
import Select, { createFilter } from "react-select";
// import styles from "../../../Home.module.css";
import { useTranslation } from "react-i18next";

 export const DasSelectOptions = (props:any) => {
  const {
    customProp,
    label,
    input,
    inputLabel,
    isRequired,
    placeholder,
    meta: { error, touched,initial },
    FieldArrayProvider,
    dataType,
    ...rest
  } = useFieldApi(props);
  let isReadOnly = false;
  if (initial) {
    isReadOnly = true;
  }
  const { t } = useTranslation();
  const placeholderLabel = t(placeholder);
  const input_Label = t(inputLabel);
  
  return (
    <div className="inputgroup">
      <label className="input-label">{input_Label}</label>
      <span className={error && touched ? "errors-class" : ""}>
        <Select
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              fontSize: "14px",
              color: "black",
              fontWeight: 400,
              borderRadius: 100,
              margin: "0 !important",
              borderColor: "#dadada",
              padding: "0 10px",
            }),
            menuList: (base) => ({
              ...base,
              fontSize: "14px",
              fontWeight: 400,
            }),
          }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 3,
            colors: {
              ...theme.colors,
              primary25: "#e5a923",
              primary: "#e5a923",
            },
          })}
          className="select-country"
          id={input.name}
          placeholder={placeholderLabel}
          // isDisabled={isReadOnly}
          {...input}
          {...rest}
          filterOption={createFilter({ matchFrom: "start" })}
        />
      </span>
    </div>
  );
};
