import React from 'react'
// import ApplePay from "../../assets/applepay.png"

const PayWithApplePay = () => {
    return (
        <>
            <span className="apple-pay-span">
                {/* <p>
                    Pay With
                </p>
                <img alt="apple"
                    src={ApplePay}
                    className="applepaylogo"
                />
                <p>
                    Pay
                </p> */}
            </span>
        </>
    )
}

export default PayWithApplePay
