import React, { useEffect, useState } from "react";
import ukflag from "../assets/flag/uk.png";
import jpflag from "../assets/flag/jp.png";
import { useNavigate } from "react-router-dom";
import { URL_LANGUAGE } from "../../constants/inital-lang.c";
import { Select, MenuItem } from "@mui/material";
import { LANGUAGES } from "../../constants/languages.c";

const SelectLanguage = () => {
  const [language, setLanguage] = useState(URL_LANGUAGE);
  const navigate = useNavigate();
  const handleChange = (event: any) => {
    localStorage.setItem("lang", event.target.value);
    let originalURL = window.location.pathname;
    let modifiedURL;
    if (event.target.value === LANGUAGES.EN) {
      modifiedURL = originalURL.replace("/jp/", "/");
    } else {
      modifiedURL = originalURL.replace("/", "/jp/");
    }
    // if we have an modified url like /jp/en/HostedPaymentPage then I am changing it to /jp/HostedPaymentPage
    if (modifiedURL.split("/").includes(LANGUAGES.EN)) {
      modifiedURL = modifiedURL.replace("/en/", "/");
    }
    navigate(modifiedURL);
    window.location.reload();
  };

  useEffect(() => {
    setLanguage(URL_LANGUAGE);
  }, [language]);

  const Menus = [
    {
      value: LANGUAGES.EN,
      src: ukflag,
      alt: "ukflag",
      label: "English",
    },
    {
      value: LANGUAGES.JP,
      src: jpflag,
      alt: "jpflag",
      label: "日本",
    },
  ];
  return (
    <>
      <div className="language-select">
        <Select
          className="language-selection"
          placeholder="Select Language"
          onChange={handleChange}
          value={language}
        >
          {Menus.map((item: any, index: number) => {
            return (
              <MenuItem value={item.value} key={index}>
                <img className="flag-img" src={item.src} alt={item.alt} />
                <span className="language-label">{item.label}</span>
              </MenuItem>
            );
          })}
        </Select>
      </div>
    </>
  );
};
export default SelectLanguage;
