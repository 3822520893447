import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./lang/en";
import { jp } from "./lang/jp";
import { URL_LANGUAGE } from "./constants/inital-lang.c";
import { LANGUAGES } from "./constants/languages.c";
const resources = {
  en: { translation: en },
  jp: { translation: jp },
};

i18n.use(initReactI18next).init({
  resources,
  lng: URL_LANGUAGE,
  fallbackLng: LANGUAGES.EN,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
