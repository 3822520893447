import {
  COMPONENT,
  FORM_FIELD_NAMES,
} from "../../../../constants/form-field-names";
import {
  MAX_AMOUNT,
  MIN_NUMBER_VALUE,
  MIN_NUMBER_VALUE_FOR_HKD,
  MIN_NUMBER_VALUE_FOR_PHP,
  MIN_NUMBER_VALUE_FOR_USD,
} from "../../../../constants/max-amount.c";
import styles from "../../../../Home.module.css";
import validatorTypes from "@data-driven-forms/react-form-renderer/validator-types";
import { commonSchema } from "./commonSchema";

export const PaymentSchema: any = (hasGooglepayOrApplePay: any, modelType: any, isJapaneseLanguage=false) => {
  const sgdAmountSchema = {
    component: COMPONENT.DAS_TEXT_FIELD,
    name: FORM_FIELD_NAMES.AMOUNT,
    inputLabel: "merchant.Payment_Information.fields.Amount",

    placeholder: "merchant.Payment_Information.fields.Amount",
    isRequired: true,
    className: hasGooglepayOrApplePay
      ? [styles.formcontrol, "add-margin-amount", "sgdamount"].join(" ")
      : [styles.formcontrol, "remove-margin-amount", "sgdamount"].join(" "),
    validate: [
      {
        type: validatorTypes.MIN_NUMBER_VALUE,
        includeThreshold: true,
        value: MIN_NUMBER_VALUE_FOR_USD,
      },
      {
        type: validatorTypes.REQUIRED,
      },
      {
        type: validatorTypes.MAX_NUMBER_VALUE,
        value: MAX_AMOUNT,
      },
    ],
    type: "number",
    dataType: "float",
  };
  const jpyAmountSchema = {
    component: COMPONENT.DAS_TEXT_FIELD,
    name: FORM_FIELD_NAMES.AMOUNT,
    inputLabel: "merchant.Payment_Information.fields.Amount",

    placeholder: "merchant.Payment_Information.fields.Amount",
    isRequired: true,
    className: hasGooglepayOrApplePay
      ? [styles.formcontrol, "add-margin-amount", "jpyamount"].join(" ")
      : [styles.formcontrol, "remove-margin-amount", "jpyamount"].join(" "),
    validate: [
      {
        type: validatorTypes.MIN_NUMBER_VALUE,
        includeThreshold: true,
        value: MIN_NUMBER_VALUE,
      },
      {
        type: validatorTypes.REQUIRED,
      },
      {
        type: validatorTypes.MAX_NUMBER_VALUE,
        value: MAX_AMOUNT,
      },
    ],
    type: "number",
    dataType: "integer",
  };
  const usdAmountSchema = {
    component: COMPONENT.DAS_TEXT_FIELD,
    name: FORM_FIELD_NAMES.AMOUNT,
    inputLabel: "merchant.Payment_Information.fields.Amount",

    placeholder: "merchant.Payment_Information.fields.Amount",
    isRequired: true,
    className: hasGooglepayOrApplePay
      ? [styles.formcontrol, "add-margin-amount", "usdamount"].join(" ")
      : [styles.formcontrol, "remove-margin-amount", "usdamount"].join(" "),
    validate: [
      {
        type: validatorTypes.MIN_NUMBER_VALUE,
        includeThreshold: true,
        value: MIN_NUMBER_VALUE_FOR_USD,
      },
      {
        type: validatorTypes.REQUIRED,
      },
      {
        type: validatorTypes.MAX_NUMBER_VALUE,
        value: MAX_AMOUNT,
      },
      {
        type: validatorTypes.PATTERN,
        pattern: /^(\d{1,5}|\d{0,5}\.\d{1,2})$/,
        message: "merchant.Payment_Information.AmountError",
      },
    ],
    type: "number",
    dataType: "float",
  };

  const hkddAmountSchema = {
    component: COMPONENT.DAS_TEXT_FIELD,
    name: FORM_FIELD_NAMES.AMOUNT,
    inputLabel: "merchant.Payment_Information.fields.Amount",

    placeholder: "merchant.Payment_Information.fields.Amount",
    isRequired: true,
    className: hasGooglepayOrApplePay
      ? [styles.formcontrol, "add-margin-amount", "hkdamount"].join(" ")
      : [styles.formcontrol, "remove-margin-amount", "hkdamount"].join(" "),
    validate: [
      {
        type: validatorTypes.MIN_NUMBER_VALUE,
        includeThreshold: true,
        value: MIN_NUMBER_VALUE_FOR_HKD,
      },
      {
        type: validatorTypes.REQUIRED,
      },
      {
        type: validatorTypes.MAX_NUMBER_VALUE,
        value: MAX_AMOUNT,
      },
      {
        type: validatorTypes.PATTERN,
        pattern: /^(\d{1,5}|\d{0,5}\.\d{1,2})$/,
        message: "merchant.Payment_Information.AmountError",
      },
    ],
    type: "number",
    dataType: "float",
  };
  const phpAmountSchema = {
    component: COMPONENT.DAS_TEXT_FIELD,
    name: FORM_FIELD_NAMES.AMOUNT,
    inputLabel: "merchant.Payment_Information.fields.Amount",

    placeholder: "merchant.Payment_Information.fields.Amount",
    isRequired: true,
    className: hasGooglepayOrApplePay
      ? [styles.formcontrol, "add-margin-amount", "phpamount"].join(" ")
      : [styles.formcontrol, "remove-margin-amount", "phpamount"].join(" "),
    validate: [
      {
        type: validatorTypes.MIN_NUMBER_VALUE,
        includeThreshold: true,
        value: MIN_NUMBER_VALUE_FOR_PHP,
      },
      {
        type: validatorTypes.REQUIRED,
      },
      {
        type: validatorTypes.MAX_NUMBER_VALUE,
        value: MAX_AMOUNT,
      },
      {
        type: validatorTypes.PATTERN,
        pattern: /^(\d{1,5}|\d{0,5}\.\d{1,2})$/,
        message: "merchant.Payment_Information.AmountError",
      },
    ],
    type: "number",
    dataType: "float",
  };
  const data = {
    JPY: {
      fields: [jpyAmountSchema, ...commonSchema(modelType,isJapaneseLanguage)],
    },
    SGD: {
      fields: [sgdAmountSchema,...commonSchema(modelType,isJapaneseLanguage)],
    },
    USD: {
      fields: [usdAmountSchema,...commonSchema(modelType,isJapaneseLanguage)],
    },
    HKD: {
      fields: [hkddAmountSchema,...commonSchema(modelType,isJapaneseLanguage)],
    },
    PHP: {
      fields: [phpAmountSchema,...commonSchema(modelType,isJapaneseLanguage)],
    },
  };
  return data;
};
