

export const ERROR_RESPONSE : any = {
    404: 
        {
            "code": "error404.code",
            "message": "error404.message",
            "description": "error404.description"
        },
    499: 
        {
            "message": "errorcancel499.message",
            "heading": "errorcancel499.heading",
            "description": "errorcancel499.description_WithoutCancelUrl"
        },
    500: 
        {
            "code": "error500.code",
            "message": "error500.message",
            "description": "error500.description"
        },
    400: 
        {
            "code": "error400.code",
            "message": "error400.message",
            "description": "error400.description"
    }

}