import React from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { CURRENCY_SYMBOL } from "../../constants/currency-symbol.c";
import { ENTITY } from "../../constants/entity.c";

export default function ProductView({
  amountLabel,
  currency,
  productList,
  entity,
}: {
  amountLabel: any;
  currency: any;
  productList: any;
  entity: any;
}) {
  const { t } = useTranslation();
  const totalPrice = (Quantity: number, Price: number) => {
    return Quantity * Price;
  };
  return (
    <>
      {productList.length > 0 && (
        <Grid className="product-list-wrap" item xs={6}>
          <div className="product-lists">
            <div className="product-total-amount">
              <h4>
                {t("merchant.Order_Overview")}
                {/* <span>
                {CURRENCY_SYMBOL[currency]}
                {entity === ENTITY.JP ? amountLabel : amountLabel.toFixed(2)}
                 <strong> {currency}</strong>
              </span> */}
              </h4>
            </div>
            <div className="products-listings">
              <div className="mobile-view-wrap">
                {productList?.map((paymentconfig: any, index: any) => (
                  <React.Fragment key={index}>
                    <div className="product-box">
                      <div className="product-image">
                        <img
                          draggable="false"
                          className="pro-image"
                          src={paymentconfig.Image || "/products/default.jpg"}
                          alt="Product"
                        />
                      </div>
                      <div className="product-details">
                        <h5 className="product-name">{paymentconfig.Name}</h5>
                        <h6 className="product-quantity">
                          <strong className="payment-quantitys">
                            {paymentconfig.Quantity}
                          </strong>
                          <span> X </span>
                          <span className="multiply-price">
                            {" "}
                            {CURRENCY_SYMBOL[currency]}
                            {paymentconfig.Price}
                          </span>
                        </h6>
                        <h6 className="product-price p-total-price">
                          {CURRENCY_SYMBOL[currency]}
                          {totalPrice(
                            paymentconfig.Quantity,
                            paymentconfig.Price
                          )}
                        </h6>
                      </div>
                    </div>
                  </React.Fragment>
                ))}
              </div>

              <div
                className="total-price"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <h4>{t("ProductPopup.Total_Price")}</h4>
                <h5>
                  <span>
                    {CURRENCY_SYMBOL[currency]}
                    {amountLabel
                      ? entity === ENTITY.JP.toUpperCase()
                        ? amountLabel
                        : amountLabel?.toFixed(2)
                      : 0}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
}
