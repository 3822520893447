import GooglePayButton from "@google-pay/button-react";
import axios from "axios";
import {
  BAD_REQUEST_PAGE,
  SERVER_ERROR_PAGE,
  SUCCESS_PAGE,
} from "../../constants/path.c";
// import { Buffer } from "buffer";
import { isJapanese } from "../hooks/isJapanese";
import { BASE_API_URL } from "../../constants/api-path.c";
import "./style.css";
import { applePayController } from "../apple-pay-button/components/apple-pay";
import {  useGoogleAnalyticsClickEvent, categoryGA } from '../hooks/google-analytics';

export const DasGooglePayButton = ({
  apiData,
  slug,
  navigate,
  setDasLoader,
}: any) => {
  const pushClickEventAnalytics = useGoogleAnalyticsClickEvent();  // For google analytics
  function onPaymentAuthorized(paymentData: any) {
      localStorage.setItem("slug", slug);

    const { token } = paymentData?.paymentMethodData?.tokenizationData;
    if (token) {
      return Promise.resolve({ transactionState: "SUCCESS" });
    } else {
      return Promise.resolve({
        transactionState: "ERROR",
        error: {
          intent: "PAYMENT_AUTHORIZATION",
          message: "Insufficient funds",
          reason: "PAYMENT_DATA_INVALID",
        },
      });
    }
  }
  function handleLoadPaymentData(paymentData: any) {
    pushClickEventAnalytics(categoryGA.googlePay,` ${slug} : payment submitted`); // For google analytics

    setDasLoader(true);

    return new Promise(function (resolve, reject) {

      const { token } = paymentData?.paymentMethodData?.tokenizationData;
      const { info } = paymentData?.paymentMethodData;

      const BACKEND_URL_PAY = `${BASE_API_URL}/wallet/capture/payment`;
      const payload = {
        URLKey: slug,
        amount: Number(apiData.amount),
        payment_method: {
          network:info?.cardNetwork,
          type: "googlepay",
          googlePayToken: JSON.stringify(token),
        },
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      // Make an HTTP POST request to your server to process the payment
      axios
        .post(BACKEND_URL_PAY, payload, {
          headers: {
            "x-secret-key": process.env.REACT_APP_PUBLIC_SECRET_KEY,
            "x-api-key": process.env.REACT_APP_PUBLIC_API_KEY,
          },
        })
        .then((response: any) => response.data)
        .then((result: any) => {
          // Handle the server's response here
          if (result.success) {
            const japanese = isJapanese();
            let redirectURL;
            // const objJsonStr = JSON.stringify(result);
            // const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
            let newResult = result?.Ref;
            if (result.hasOwnProperty("success") && result.success === true) {
              redirectURL = `/${SUCCESS_PAGE}/${newResult}`;
            } else {
              redirectURL = `/${BAD_REQUEST_PAGE}`;
            }
            if (japanese) {
              navigate(`/jp${redirectURL}`);
            } else {
              navigate(redirectURL);
            }
            // resolve({});
          } else {
            // Handle the payment error case
            reject({ message: result.message });
          }
        })
        .catch((error) => {
          const result = error.response.data;
          const japanese = isJapanese();
          let redirectURL;
          // const objJsonStr = JSON.stringify(result);
          // const objJsonB64 = Buffer.from(objJsonStr).toString("base64");
          if (result.hasOwnProperty("success") && result.success === false) {
            redirectURL = `/${SERVER_ERROR_PAGE}/${result?.Ref}`;
          } else {
            redirectURL = `/${BAD_REQUEST_PAGE}`;
          }
          if (japanese) {
            navigate(`/jp${redirectURL}`);
          } else {
            navigate(redirectURL);
          }
          // reject({ message: "Payment processing failed" });
        });
    });
  }
  return (
    <div
      className={
        (apiData?.hasGooglePay === true && apiData?.hasApplePay === false) ||
        !applePayController.isAppleSessionAvailable()
          ? "google-pay-button full-width-button"
          : "google-pay-button"
      }
    >
      <GooglePayButton
        environment={
          (process.env.REACT_APP_PUBLIC_GOOGLE_PAY_ENVIRONMENT || "TEST") as
            | "TEST"
            | "PRODUCTION"
        }
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["MASTERCARD", "VISA", "AMEX"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway:
                    process.env.REACT_APP_PUBLIC_GOOGLE_PAY_GATEWAY ||
                    "worldpay",
                  gatewayMerchantId: apiData?.gGatewayMchId || process.env.REACT_APP_PUBLIC_GOOGLE_PAY_GETEWAY_MERCHANT_ID || "",
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: apiData?.gMchId || process.env.REACT_APP_PUBLIC_GOOGLE_PAY_MERCHANT_ID || "",
            merchantName: apiData?.company,
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPriceLabel: "Total",
            totalPrice: apiData?.amount.toString() || "0",
            currencyCode: apiData?.currency,
            countryCode: apiData?.country?.value,
            displayItems: [
              {
                label: "Subtotal",
                type: "SUBTOTAL",
                price: apiData?.amount.toString(),
              },
              // {
              //   label: 'Tax',
              //   type: 'TAX',
              //   price: '1.00',
              // },
            ],
          },
          // optional to enable shipping address
          shippingAddressRequired: true,
          callbackIntents: ["PAYMENT_AUTHORIZATION"],
        }}
        onLoadPaymentData={handleLoadPaymentData}
        onPaymentAuthorized={onPaymentAuthorized as any}
        buttonType="plain"
        style={{ width: 480, height: 30 }}
        buttonSizeMode="fill"
      />
    </div>
  );
};
