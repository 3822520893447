import { CARDINAL_URL, BASE_API_URL } from "../../constants/api-path.c";
import { SERVER_ERROR_PAGE } from "../../constants/path.c";
import Api from "../../utils/Api";

export const cardinal3ds = (
  slug: string,
  setSessionID: any,
  setJwt: any,
  navigate: any
) => {
  window.addEventListener(
    "message",
    function (event) {
      //This is a Cardinal Commerce URL in live.
      if (event.origin === CARDINAL_URL) {
        if (typeof event.data === "string") {
          var data = JSON.parse(event.data);
          // console.warn('Merchant received a message:', data);
          if (data !== undefined && data?.Status) {
            setSessionID(data?.SessionId);
          } else {
            return;
          }
        } else {
          return;
        }
      }
    },
    false
  );

  Api()
    .post(`${BASE_API_URL}/threeds/jwt/create`, { URLKey: slug })
    .then((result) => {
      setJwt(result.jwt);
    })
    .catch(() => {
      navigate(`/${SERVER_ERROR_PAGE}`);
    });
};
