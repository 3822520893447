import React, { useState } from 'react'
import CustomAccordian from '../../../custom-accordian/CustomAccordian';
import ModelTypeOne from './ModelTypeOne';

const AccordionModelTypeOne = (props:any) => {

    const { hideBillingAddress, billingInfoLabel, emailAndPhone, cityAndAddress, countryField, postalCodeField } = props;
    
    const [open, setOpen] = useState(false);

    if (open) {
        return (<CustomAccordian
            hideBillingAddress={hideBillingAddress}
            button={billingInfoLabel}
            open={open}
            setOpen={setOpen}
        >
            <ModelTypeOne {...props} />
        </CustomAccordian>)
    } else {
        return (
            <>
                <CustomAccordian
                    hideBillingAddress={hideBillingAddress}
                    button={billingInfoLabel}
                    open={open}
                    setOpen={setOpen}
                >
                    <div className="billing-section">
                        {emailAndPhone}
                        <div className="country-postcode">{cityAndAddress}</div>
                        <div className="center">  {countryField} </div>

                    </div>
                </CustomAccordian>
                <div className="billing-section">
                    <div className="center">  {postalCodeField} </div>
                    {emailAndPhone}
                </div>
            </>
        )
    }
}

export default AccordionModelTypeOne