import React,{useEffect,useState} from 'react'

const ReverseCounter = ({count,prefixText,suffixText}:any) => {
    const [counter, setCounter] = useState(count)

    useEffect(()=>{
     if(counter>0){
        setTimeout(()=>{
            setCounter((prev:any)=>prev-1)
        },1000)
     }
    }, [counter])
  return (
    <div>
      <p className='redirect-text'>{prefixText} {counter} {suffixText}</p>
    </div>
  )
}

export default ReverseCounter;
