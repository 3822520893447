import React from 'react'

const Details = ({ COMPANY, ADDRESS,
    //  CURRENCY, amountLabel 
    }: any) => {
    return (
        <>
            <div className="logo-div">
                <h5 title={COMPANY} className="company-name">{COMPANY}</h5>
                <p title={ADDRESS} className="company-address">{ADDRESS}</p>
            </div>
      
        </>
    )
}

export default Details
