import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SUCCESS from "../../components/assets/payment/payment-successful.png";
import { REDIRECT_COUNT } from "../../constants/timeout-intervals";
import ReverseCounter from "../../components/reverse-counter/ReverseCounter";
import { NavLink } from "react-router-dom";
import Api from "../../utils/Api";
import Loader from "../../components/loader/Loader.component";
import { BASE_API_URL } from "../../constants/api-path.c";
import { EmailVerifyRegex } from "../../constants/regex.c";
import HppWrapper from "../../components/main-wrapper/HppWrapper";
import { LANGUAGES } from "../../constants/languages.c";
import { CURRENCY_CODES } from "../../constants/currency-codes.c";


const SuccessBox = ({
  Merchant,
  success_url,
  amount = "",
  Ref = "",
  currencyCode = "",
  transactionId,
  slug,
  language,
}: any) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [showEmailBox, setShowEmailBox] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const sendEmail = (email:string) => {
    setError("");
    Api()
      .post(`${BASE_API_URL}/payment/send-receipt`, {
        URLKey: slug,
        transactionId: transactionId,
        language: language.toUpperCase() || "en",
        email,
      })
      .then((res) => {
        if (res?.message === "Invoice Sent Successfully!") {
          setShowEmailBox(false);
          setLoading(false);
          setEmailSent(true)
          // localStorage.removeItem("hppemail");
        } else if ([500].includes(res?.statusCode)) {
          setLoading(false);
          setError("Sorry something went wrong! Please try again!");
          setLoading(false);
        }
      });
  };
  const localStorageEmail  = localStorage.getItem("hppemail");

  useEffect(()=>{
    if(localStorageEmail){
      sendEmail(localStorageEmail);
    }else{
      setShowEmailBox(true)
    }
    },[]);

  return (
    <>
      {loading && <Loader />}
      <HppWrapper>
        <div className="w-40rem">
          <div className="background-wrapper">
            <div className="payment-container">
              <React.Fragment>
                <div className="combo-cont">
                  <div className="payment-box">
                    <div className="payment-img-container">
                      <img
                        src={SUCCESS}
                        className="payment-img-icon"
                        alt="Icon"
                      />
                      <h4 className="payment-status payment-success-green">
                        {t("Payment_Successful.status")}
                      </h4>
                    </div>
                    <p className="text-center">
                      {/* {t("Payment_Successful.recipient")} */}
                      {/* <strong>&nbsp;{Merchant}</strong> */}
                    </p>
                    <hr className="dotted"></hr>
                    <div className="pay-info">
                      <h5 className="amt-info">
                        {t("Payment_Successful.Amount_Paid")}
                        {
                          language === LANGUAGES.JP && currencyCode === CURRENCY_CODES.JPY ?
                            <span>{`${amount} ${t(`countryCode.${currencyCode}`)}`}</span>
                            :
                            <span>{`${t(`countryCode.${currencyCode}`)} ${amount}`}</span>

                        }
                      </h5>
                      <h5>
                        {t("Payment_Successful.Transaction_reference")}
                        <span>{Ref}</span>
                      </h5>
                    </div>
                  </div>

                  {showEmailBox && (
                    <div className="news-letter-div">
                      <label>
                        {t("Please_Enter_An_Email_ID_To_Receive_The_Receipt")}
                      </label>
                      <div className="newsletter-input">
                        <input
                          name="email"
                          type="email"
                          placeholder={t("Email_ID")}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button
                        className="send-email-button"
                          disabled={!EmailVerifyRegex.test(email)}
                          onClick={() => {
                            sendEmail(email);
                            setLoading(true);
                          }}
                        >
                          <span className="send-text"> {t("Send Email")} </span> 
                          {/* <span className="send-icon"> &#10148;</span> */}
                        </button>
                      </div>
                      {/* <p className="error-sendemail">{error}</p> */}
                    </div>
                  ) }
                  {
                    emailSent && <h3 className="email-sent-successfully">
                    {t("Email_Sent_Successfully")}
                  </h3>
                  }
              
                  <div className="tcenter">
                    {!success_url ? (
                      ""
                    ) : (
                      <NavLink className="back-home" to={success_url}>
                        {t("Payment_Successful.Prev_Link")}
                      </NavLink>
                    )}
                  </div>
                  {!success_url ? (
                    ""
                  ) : (
                    <ReverseCounter
                      count={REDIRECT_COUNT.SUCCESS}
                      prefixText={t("Payment_Successful.Redirect_Prefix_Text")}
                      suffixText={t("Payment_Successful.Redirect_Suffix_Text")}
                    />
                  )}
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
      </HppWrapper>
    </>
  );
};

export default SuccessBox;
